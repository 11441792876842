import Link from "next/link";

import { NavLinks } from "./NavLinks";

export function Footer() {
  return (
    <footer className="footer_cont border-brand-400 border-t">
      <div className="btn_cont !items-center pr-2">
        <Link href="/" className="footer_logo">
          <img src="footer_logo.svg" loading="lazy" width="106" alt="FanClub Logo" />
        </Link>

        <Link
          href="https://apps.apple.com/us/app/fanclub/id1535897256
">
          <button className="btn_main w-button hover:brightness-125">Mobile App</button>
        </Link>
      </div>
      <div className="footer_center">
        <NavLinks />
      </div>
      <div className="footer_right">
        <div className="p_link_upper">
          <div className="p_img">
            <Link href="https://www.facebook.com/FanClubtheapp/" rel="noopener noreferrer" target="_blank">
              <img src="fb.svg" loading="lazy" alt="fb" />
            </Link>
          </div>
          <div className="p_img">
            <Link href="https://twitter.com/FanClubapp_" rel="noopener noreferrer" target="_blank">
              <img src="twitter.svg" loading="lazy" alt="twitter" />
            </Link>
          </div>
        </div>
        <div className="p_link_lower">
          <div className="p_img">
            <Link href="https://www.tiktok.com/@fanclubapp" rel="noopener noreferrer" target="_blank">
              <img src="tik.svg" loading="lazy" alt="tiktok" />
            </Link>
          </div>
          <div className="p_img">
            <Link href="https://www.instagram.com/fanclubapp_/" rel="noopener noreferrer" target="_blank">
              <img src="insta.svg" loading="lazy" alt="instagram" />
            </Link>
          </div>
          <div className="p_img">
            <Link href="https://www.linkedin.com/company/fanclub/" rel="noopener noreferrer" target="_blank">
              <img src="linkedin.svg" loading="lazy" alt="linkedin" />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}
