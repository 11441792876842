import Link from "next/link";

export function Header() {
  return (
    <header>
      <nav className="nav_logo flex items-stretch pl-[20px] pr-[20px] pt-[1.25rem] sm:pl-[60px] sm:pr-[60px] ">
        <Link href="/" className="nav_cont max-w-[45%] sm:max-w-[30%]">
          <img src="navbar_logo.png" alt="" />
        </Link>
        <div className="btn_cont">
          <Link href="/video-chat-with/All">
            <button className="btn_main w-button hover:brightness-125">Discover Creators</button>
          </Link>
          <Link href="/auth/login">
            <button className="btn_main w-button hover:brightness-125">Sign up or Log in</button>
          </Link>
        </div>
      </nav>
    </header>
  );
}
