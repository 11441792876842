import type { GetServerSidePropsContext } from "next";
import { useEffect } from "react";

import { getServerSession } from "@calcom/features/auth/lib/getServerSession";
import useTheme from "@calcom/lib/hooks/useTheme";
import { localStorage } from "@calcom/lib/webstorage";
import { HeadSeo } from "@calcom/ui";

import PageWrapper from "@components/PageWrapper";

import { Footer } from "../components/landing/Footer";
import { Header } from "../components/landing/Header";
import Landing from "../components/landing/Landing";
import { SecondaryFeatures } from "../components/landing/SecondaryFeatures";

function RedirectPage() {
  useEffect(() => {
    localStorage.setItem("referredby", "");
  }, [localStorage]);
  useTheme("dark");
  return (
    <>
      <HeadSeo
        title="Discover and Meet with Content Creators"
        description="FanClub is the premier Creator-centric social media platform where you can connect with content creators and other online experts. Book time and meet with Content Creators. Create and share memorable moments with Creators on the FanClub app."
        canonical="https://fanclub.app/"
        useTag
      />

      <Header />
      <main>
        <Landing />
        <SecondaryFeatures />
      </main>
      <Footer />

      <style global jsx>{`
        body,
        html {
          background-color: #0a0e17 !important;
          color: white !important;
        }
      `}</style>
    </>
  );
}

RedirectPage.PageWrapper = PageWrapper;

export async function getServerSideProps({ req, res }: GetServerSidePropsContext) {
  const session = await getServerSession({ req, res });

  if (session?.user?.id) {
    return { redirect: { permanent: false, destination: "/event-types" } };
  }

  return {
    props: {},
  };
}

export default RedirectPage;
