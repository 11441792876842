import { useRouter } from "next/navigation";
import React, { useState, useEffect } from "react";
import ReactStars from "react-rating-star-with-type";

import { useDebounce } from "@calcom/lib/hooks/useDebounce";
import { trpc } from "@calcom/trpc/react";
import { Select } from "@calcom/ui";
import { TextField } from "@calcom/ui";
import { Spinner } from "@calcom/ui/components/icon/Spinner";

import { QueryCell } from "@lib/QueryCell";

const Creator = () => {
  const tagQuery = trpc.viewer.tags.list.useQuery(
    {},
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );
  const router = useRouter();
  const [allCreators, setAllCreators] = useState([]);
  const [filteredCreators, setFilteredCreators] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [selectedTag, setSelectedTag] = useState();
  const [skip, setSkip] = useState(undefined);
  const [premiumSkip, setPremiumSkip] = useState(undefined);
  const creatorQuery = trpc.viewer.public.discoverCreator.useQuery(
    selectedTag?.value !== "All"
      ? { filter: selectedTag?.value, searchTerm: debouncedSearchTerm, skip, premiumSkip }
      : { searchTerm: debouncedSearchTerm, skip, premiumSkip },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const options = tagQuery.data
    ? [
        { label: "All", value: "All" },
        ...tagQuery.data.map((tag) => ({ label: tag.title, value: tag.title })),
      ]
    : [];

  const handleLoadMore = () => {
    setPremiumSkip(creatorQuery?.data?.nextPremiumCursor);
    setSkip(creatorQuery?.data?.nextCursor);
  };

  useEffect(() => {
    if (creatorQuery.data) {
      const newCreators = creatorQuery.data?.creators || [];
      setAllCreators((prevCreators) => {
        const updatedCreators = Array.from(new Set([...prevCreators, ...newCreators]));

        // Remove duplicates based on the creator's id
        return Array.from(new Map(updatedCreators.map((creator) => [creator.username, creator])).values());
      });
    }
  }, [creatorQuery?.data]);
  useEffect(() => {
    const filteredCreators = allCreators.filter((creator) => {
      const hasMatchingTags =
        selectedTag && selectedTag.value !== "All"
          ? creator.fanclubTags.some((tag) =>
              tag?.title?.toLowerCase()?.includes(selectedTag?.value.toLowerCase())
            )
          : true;

      const hasMatchingSearchTerm = searchTerm
        ? creator.name.toLowerCase().includes(searchTerm.toLowerCase())
        : true;

      return hasMatchingTags && hasMatchingSearchTerm;
    });

    // Remove duplicates from filtered results
    const uniqueFilteredCreators = Array.from(
      new Map(filteredCreators.map((creator) => [creator.username, creator])).values()
    );

    // Update the filtered state
    setFilteredCreators(uniqueFilteredCreators);
  }, [searchTerm, selectedTag, allCreators]);
  return (
    <div className="creator_section">
      <div className="creator_cont">
        <div className="filter_cont flex">
          <h1 className="vide_h1">Creators</h1>
          <TextField
            placeholder="Creator Name"
            label="Search"
            className=" w-full md:min-w-[15vw] lg:min-w-[20vw]"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <div className="filter_drop_cont">
            <p className="sort_by mr-2">Sort by Tag: </p>
            <Select
              className="h-auto !min-w-[100px] !bg-black text-center text-sm"
              options={options}
              value={selectedTag || { label: "All", value: "All" }}
              onChange={(value) => {
                setSelectedTag(value);
                setSkip(0);
              }}
            />
          </div>
        </div>
        <div className="card_cont">
          <QueryCell
            query={creatorQuery}
            empty={() => null}
            success={({ data }) => (
              <>
                {filteredCreators.map((creator) => (
                  <>
                    {creator.avatar.includes("data:") ? (
                      <div
                        key={creator?.username}
                        onClick={() => router.push(`${creator?.username ?? ""}`)}
                        className="card_fan_profile cursor-pointer">
                        <div className="profile_pic_cont">
                          <img
                            src={
                              creator.avatar
                                ? creator.avatar
                                : `https://ui-avatars.com/api/?name=${encodeURIComponent(
                                    creator.name
                                  )}&rounded=true&background=random&size=128`
                            }
                            loading="lazy"
                            alt={creator.name}
                            className="profile_img"
                            onError={(e) => {
                              e.target.src = `https://ui-avatars.com/api/?name=${encodeURIComponent(
                                creator.name
                              )}&rounded=true&background=random&size=128`;
                              e.target.alt = creator.name; // Set alt to an empty string if an error occurs
                            }}
                          />
                        </div>
                        <h1 className="card_h1">{creator.name}</h1>{" "}
                        {creator?.rating && (
                          <div className=" mb-4 flex flex-wrap items-center justify-center gap-2">
                            <ReactStars
                              size="1rem"
                              // onChange={onChange}
                              value={creator.rating}
                              isEdit={false}
                              classNames="!gap-1  text-center "
                              activeColors={["#FFCB45", "#FFCB45", "#FFCB45", "#FFCB45", "#FFCB45"]}
                            />{" "}
                            <p className="text-subtle text-[1rem]">
                              <span className="   font-bold  text-[#FFCB45]"> {creator.rating}</span> (
                              {creator.ratingCount})
                            </p>
                          </div>
                        )}
                        <div className="tags_cont">
                          <p className="card_p text-center">
                            {creator.fanclubTags.length > 1
                              ? ` ${creator.fanclubTags.map((tag) => tag.title).join(", ")}`
                              : creator.fanclubTags[0]?.title || ""}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                ))}
              </>
            )}
          />
        </div>

        {!(filteredCreators?.length === 24) &&
          (creatorQuery?.data?.nextCursor || creatorQuery?.data?.nextPremiumCursor !== -1) &&
          !creatorQuery.isFetching && (
            <div className="load_more_cont">
              <button
                onClick={handleLoadMore}
                className="btn_main brand_btn load_more w-button hover:brightness-125">
                Load More
              </button>
            </div>
          )}
        {!creatorQuery.isLoading && creatorQuery.isFetching && (
          <div className="flex items-center justify-center">
            <Spinner className="mt-12 h-8 w-8" />
          </div>
        )}
      </div>
    </div>
  );
};

export default Creator;
