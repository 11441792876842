import Link from "next/link";
import { useState } from "react";

export function NavLinks() {
  let [hoveredIndex, setHoveredIndex] = useState(null);

  return [
    ["Privacy Policy", "/about/privacy"],
    ["Terms", "/about/terms-and-conditions"],
    ["Contact", "mailto:support@gofanclub.com"],
    ["Blogs", "https://blogs.fanclub.app"],
    ["Guides", "https://blogs.fanclub.app/guides"],
  ].map(([label, href], index) => (
    <Link
      key={label}
      href={href}
      className="text-brand-200 relative -mx-3 -my-2 rounded-lg px-3 py-2 text-sm transition-colors delay-150 hover:text-white hover:delay-[0ms]"
      onMouseEnter={() => setHoveredIndex(index)}
      onMouseLeave={() => setHoveredIndex(null)}>
      <>
        {hoveredIndex === index && (
          <span
            className="absolute inset-0 rounded-lg"
            layoutId="hoverBackground"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.15 } }}
            exit={{
              opacity: 0,
              transition: { duration: 0.15, delay: 0.2 },
            }}
          />
        )}
      </>
      <p className="p_foot">{label}</p>
    </Link>
  ));
}
