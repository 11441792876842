import { Container } from "./Container";

const features = [
  {
    name: "Made for Creators by Creators",
    description: "FanClub is tailored for Content Creators to manage their bookings.",
  },
  {
    name: "Booking reminders",
    description: "Set up email and mobile reminders to reduce no shows.",
  },
  {
    name: "Discover Creators",
    description: "Easily find and book time with other Creators on the FanClub platform.",
  },
  {
    name: "Free to use",
    description: "FanClub is free to use. Creators take home 95% of their total earnings.",
  },
  {
    name: "Refund support",
    description: "FanClub supports Creators and facilitates refunds if a paid meeting doesn’t work out.",
  },
  {
    name: "Builtin video solution",
    description: "No need to pay for a separate video solution like Zoom, FanClub has it built in.",
  },
  {
    name: "Safety is #1 Concern",
    description:
      "FanClub is always on the side of Creators and offers protection against any harmful actions",
  },
  {
    name: "Save and Share Recordings",
    description: "Feel free to share your Moments in the form of screenshots, video, or even NFTs",
  },
  {
    name: "Authenticity",
    description: "FanClub promotes Creators to freely express themselves for their fans",
  },
];

export function SecondaryFeatures() {
  return (
    <section
      id="secondary-features"
      aria-label="Features for building a portfolio"
      className="py-20 sm:py-32">
      <Container>
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-3xl font-medium tracking-tight">
            FanClub is the best way to meet and engage your fans.
          </h2>
          <p className="text-brand-200 mt-2 text-lg">
            Here are some benefits over other booking solutions like Calendly
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-6 text-sm sm:mt-20 sm:grid-cols-2 md:gap-y-10 lg:max-w-none lg:grid-cols-3">
          {features.map((feature) => (
            <li key={feature.name} className="border-brand-400 rounded-2xl border p-8">
              <h3 className="font-semibold">{feature.name}</h3>
              <p className="text-brand-200 mt-2">{feature.description}</p>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  );
}
